import React from 'react'
import './style.css'
import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.png'
import img4 from './img/img4.png'

function Awards({t}) {

  return (
    <div className='content1150'>
      <div className='awards'>
        <div className='award-item'>
          <img
            src={img1} 
            alt=''
            style={{position:"relative", top: '-8px'}}
          /> 
          <span>{t("awards_nomination")}</span>         
        </div>
        <div className='award-item'>
          <img 
            src={img2} 
            alt=''
          />
          <span>{t("awards_nomination")}</span>
        </div>
        <div className='award-item'>
          <img 
            src={img3} 
            alt=''
            style={{width:'85%'}}
          />
          <span>{t("awards_award")}</span>
        </div>
        <div className='award-item'>
          <img 
            src={img4} 
            alt=''
          />
          <span>{t("awards_award")}</span>
        </div>
      </div>
    </div>
  );
}
 
export default Awards;
