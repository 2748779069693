import React from 'react'
import './style.css'
import logo_kramp from './img/kramp.svg'
import login from './img/login.png'
import jg from './img/jg.png'
import seat from './img/seat.svg'
import cuzyslow from './img/cuzyslow.png'
import gwiazdki from './img/gwiazdki2.svg'
import { Trans } from 'react-i18next';

function Recommend({isWideScreen,t}) {

  const aosOptions = {
    fadeUp: {
      'data-aos': 'fade-up',
      'data-aos-delay': '300'
    }
  };
  return (
    <div className='content1150'>
      <div className='recommend'>
        <div className='title'>
          <span
            data-aos="fade-up" 
            data-aos-delay="100" 
            
          >
            {t("recomend_title")}
          </span> 
        </div>
        <div 
          className='recommend_items'
        >
          <div 
            className='recommend_item'
            data-aos="fade-up" 
            data-aos-delay="200" 
            
          >
            <div className='item_title'>
              <div><img src={login} alt=''/></div>
              <div className='item_name'>
                Monika Leksowska
                <small>Marketing Manager</small>
              </div>
            </div>
            <div className='item_txt'>
              <img src={cuzyslow} alt=''/><Trans i18nKey={t("recomend_1")} /></div>
            <div className='item_logo'>
              <img src={logo_kramp} width={170} alt=''/>
            </div>
          </div>

          <hr className='mobile-seperator'/>

          <div 
            className='recommend_item'
            {...isWideScreen && aosOptions.fadeUp}

          >
            <div className='item_title'>
              <div><img src={jg} alt=''/></div>
              <div className='item_name'>
              Jakub Góralczyk
                <small>Head of Communications</small>
              </div>
            </div>
            <div className='item_txt'>
              <img src={cuzyslow} alt=''/><Trans i18nKey={t("recomend_2")} /></div>
            <div className='item_logo'>
              <img src={seat} width={170} alt=''/>
            </div>
          </div>

        </div>
        <div className='recommend_stars'>
          <div className='score'>
            <span className='score_label'><Trans i18nKey={t("recomend_3")} />:</span> 
            <span className='score_value'>8.50</span>
            <span className='score_scope'>/10</span>
          </div>
          <div className='score_stars'>
            <img src={gwiazdki} alt=''/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommend;
