
import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Ikea from './pages/ikea/ikea';
import Lirene from './pages/lirene/lirene';
import Kramp from './pages/kramp/kramp';
import TarczynskiPoranki from './pages/tarczynski_poranki/tarczynski_poranki';
import Tarczynski from './pages/tarczynski/tarczynski';
import VW from './pages/vw/vw';
import ScrollToTop from './hooks/ScrollToTop';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const App = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 600);
  const [wideScreen, setWideScreen] = useState(window.innerWidth);
  const { t } = useTranslation();
  
  const handleResize = () => {
    setIsWideScreen(window.innerWidth > 600);
    setWideScreen(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: !isWideScreen ? 300 : 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom',
    });
  }, [isWideScreen]);

  
  return (
    <Router basename={'/'}>
      <ScrollToTop>
        <div>
          <Routes>
            <Route path="/" element={<Home isWideScreen={isWideScreen} wideScreen={wideScreen} t={t}/>} />
            <Route exact path="/ikea" element={<Ikea isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} />
            <Route exact path="/lirene" element={<Lirene isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} />        
            <Route exact path="/kramp" element={<Kramp isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} /> 
            <Route exact path="/tarczynski-poranki" element={<TarczynskiPoranki isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} />    
            <Route exact path="/tarczynski" element={<Tarczynski isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} />  
            <Route exact path="/vw" element={<VW isWideScreen={isWideScreen} t={t} wideScreen={wideScreen}/>} />         
          </Routes>
        </div>        
      </ScrollToTop>
    </Router>
  );
};

export default App;
