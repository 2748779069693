import React from 'react';
import Navigation from '../../components/navigation/navigation';
import './styles.css'
import logo from './img/logo.png'
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import bg from './img/effects_bg.jpg'
import bullet1 from './img/bullet1.png'
import bullet2 from './img/bullet2.png'
import bilbord from './img/bilbord.png'
import { Trans } from 'react-i18next';

const Ikea = ({isWideScreen,t}) => {

  const items = [
    {
      icon: icon1,
      title: t("ikea_item_1")
    },
    {
      icon: icon2,
      title: t("ikea_item_2")
    }
  ]

  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen}/>
      <div className='page'>
        <div className='cs_baner_ikea'>
          <div className='cs_baner_container'>
            <img 
              src={logo} 
              alt=''
              data-aos="fade-up"data-aos-delay="100"
            />
            <h2 data-aos="fade-up"data-aos-delay="200" >
              <Trans i18nKey={t("ikea_banner_1")}/>
            </h2>
            <h5 data-aos="fade-up"data-aos-delay="300">
              <Trans i18nKey={t("ikea_banner_2")}/>
            </h5>            
          </div>
        </div>
        <div className='cs_black_space'></div>
        <div className='cs_container_1150_ikea'>

          <div className='challenge'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              {t("challenge")}
            </h2>
            <div className='cs_poranek_row'>
              <div className='cs_bullets'>

                <div 
                  className='cs_bullets_box'
                  data-aos="fade-up" 
                  data-aos-delay="200" 
                  
                >
                  <img src={bullet1} alt=''/>
                  <Trans i18nKey={t("ikea_point_1")}/>                
                </div>
              </div>               
            </div>
          
          </div>

          <div className='realization'>
            <div className='realization-points'>
              <div className='baner_event'>
                <img src={bilbord} alt='' data-aos="fade-up" data-aos-delay="300" />
              </div>
              <div className='points_column'>
                <h2 
                  className='title'
                  data-aos="fade-up" 
                  data-aos-delay="100" 
                  
                >
                  {t("wedid")}
                </h2>
                <div className='point' data-aos-delay="400" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                  <Trans i18nKey={t("ikea_point_2")}/>
                  </div>
                </div>
              </div>
            </div>           

          </div>

        </div>

        <PageEffects bg={bg} items={items} max={300} t={t}/>
        <PageFooter t={t}/>
      </div>

    </>
  );
};

export default Ikea;