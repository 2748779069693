import React from 'react'
import './style.css'
import { useSpring } from '@react-spring/web'
import logo from './img/logo.png'
import logoMobile from './img/logo-mobile.png'
import Social from '../../components/social/social';
import logos from './img/logos.png'
import { versions } from '../../version';

function Footer({isWideScreen, t}) {
  const [{ scrollY }, set] = useSpring(() => ({ scrollY: 0 }))
  const data = new Date();
  const year = data.getFullYear();

  const handleScroll = () => {
    set({ scrollY: window.scrollY })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])



  return (
    <div style={{position: 'relative'}}>
      <div 
        className='mask' 
        style={{ 
          top: 
            scrollY.interpolate(y => 
              `${((scrollY?.animation?.values[0]?.lastVelocity || 1) * 10)}px`) 
            }}
        ></div>
      <div 
        className='footer' 
        // style={{ backgroundPositionY: scrollY.interpolate(y => `${-(y/5)}px`) }}
      >
        <div className='footer_container'>
          <div className='footer_title-row'>
            <div className='footer-contact-logo_mobile'>
              <img src={logo} alt=''/>
            </div>
            <div className='footer_title'>
              <span>
                CRAFTED FOR
              </span> 
              <span className='green'>
                GROWTH
              </span>
            </div>            
          </div>

          <div className='footer-grid'>
            <div className='footer-contact'>
              <div className='footer-contact-grid'>
                <div className='footer-contact-logo'>
                  {!isWideScreen ? (
                    <img src={logoMobile} alt=''/>
                  ) : (
                    <img src={logo} alt=''/>
                  )}
                  
                </div>
                <div className='footer-contact-info'>
                  <div className={'mobileFooterRow'}>
                    <Social/>
                    <div>
                      <div className='footer-contact-flex'>
                        <div>
                          tel. 733 30 30 30
                        </div>
                        <div>
                          biuro@agencjakhs.pl
                        </div>                    
                      </div>
                      <div style={{marginTop: '2px'}}>
                        {t("footer_1")}
                      </div>                    
                    </div>                   
                  </div>


                  <div className='other-logos'>
                    <img src={logos} alt=''/>
                  </div>
                </div>
              </div>
            </div>
            <div className='footer-menu'>
              <ul>
                <li>
                  <a href='#contact'>{t("footer_contact")}</a>
                </li>
                <li>
                  <a href={`${process.env.PUBLIC_URL}/img/polityka-prywatnosci-KHS.pdf`} target="_blank" rel="noopener noreferrer">{t("footer_info")}</a>
                </li>
                <li>
                  <a href={`${process.env.PUBLIC_URL}/img/politykaKHS.pdf`} target="_blank" rel="noopener noreferrer">
                    {t("footer_politic")}
                  </a>                  
                </li>
              </ul>
            </div>
          </div>
          <div className='copyright'>
            KHS {year} | All rights reserved {versions}
          </div>
        </div>
      </div>    
    </div>

  )
}

export default Footer