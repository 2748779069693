import React, { useState, useEffect } from 'react'
import './style.css'
import { useSpring } from '@react-spring/web'
import logo from './img/logo.png'
import iwona from './img/face1.png'
import ewa from './img/face2.png'
import ewa2 from './img/face2m.png'
import marcin from './img/face3.png'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import { FaLinkedin } from "react-icons/fa";
import { Transition } from 'react-transition-group';
import { Trans } from 'react-i18next';

const duration = 300;

const defaultStyle = {
  transition: `max-height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  maxHeight: 0,
  opacity: 0,
  overflow: 'hidden',
}

const transitionStyles = {
  entering: { maxHeight: '1000px', opacity: 1 }, // Set maxHeight to a large enough value
  entered: { maxHeight: '1000px', opacity: 1 },  // to cover the content's full height
  exiting: { maxHeight: 0, opacity: 0 },
  exited: { maxHeight: 0, opacity: 0 },
};

function Team({isWideScreen,t}) {
  const [{ scrollY }, set] = useSpring(() => ({ scrollY: 0 }))
  const [expandedItemId, setExpandedItemId] = useState(null);

  const handleScroll = () => {
    set({ scrollY: window.scrollY })
  }

  const toggleExpand = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])



  return (
    <div className='team' style={{ backgroundPositionY: scrollY.to(y => `${-(y/5)}px`) }}>
      <div className='team_container'>
        <div className='team_title' data-aos="fade-up" data-aos-delay="200" >
          <img src={logo} width={103} alt=''/>
          <div className='title-text' style={{gap: '15px', display: 'flex'}}> 
            <span>
              {t("team_Zespol")}
            </span> 
            <span className='green'>
              {t("team_KHS")}
            </span>            
          </div>

        </div>


        <div className='team_grid'>


            <div 
              onClick={() => toggleExpand(1)} 
              className='team_item' 
              style={isWideScreen ? {marginBottom: 0} : {marginBottom: '-10px'}}
            >
              <div className='photo'>
                <img className='pic' src={iwona} alt='' />
              </div>
              <div className='team_item_content'>

                <img className='icon' src={icon1} alt='' />
                <div className='item_title'>
                  Iwona Szwajkajzer
                  <small><Trans i18nKey={t("team_1a")} /></small>
                </div>
                <div className='team-grid'>
                  <div className='item_lead'><Trans i18nKey={t("team_1b")} /></div>
                  <div className='item_text' style={{paddingRight: '4px'}}><Trans i18nKey={t("team_1c")} /></div>
                  <div className='item_btn'>
                    <a href="https://www.linkedin.com/in/iwona-szwajkajzer-01155b144/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>
                        <FaLinkedin size={24}/>
                        <span>LINKEDIN</span>
                      </button>
                    </a>
                  </div>                  
                </div>

              </div>
            </div>
            <Transition in={expandedItemId === 1 || isWideScreen} timeout={duration}>
              {state => (
                <div className='team-grid-mobile'>
                  <div className='item_lead' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_1b")} /></div>
                      
                  <div className='item_text' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_1c")} /></div>
                  <div style={expandedItemId === 1 ? {marginBottom: '20px'} : {marginBottom: '-20px'}}>
                    <div className={`item_btn`} style={{ ...defaultStyle, ...transitionStyles[state] }}>
                      <a href="https://www.linkedin.com/in/iwona-szwajkajzer-01155b144/" target="_blank" rel="noopener noreferrer">
                        <button className='btn'>
                          <FaLinkedin size={24}/>
                          <span>LINKEDIN</span>
                        </button>
                      </a>
                    </div> 
                  </div>
                 
                </div>
              )}
            </Transition>



            <div onClick={() => toggleExpand(2)} className='team_item rev'>
              <div className='photo'>
                <img className='pic' src={!isWideScreen ? ewa2 : ewa} alt='' />
              </div>
              <div className='team_item_content'>
                <img className='icon' src={icon2} alt='' />
                <div className='item_title'>
                  Ewa Jakubowska
                  <small>CEO</small>
                </div>
                <div className='team-grid'>
                  <div className='item_lead'><Trans i18nKey={t("team_2a")} /></div>
                  <div className='item_text'><Trans i18nKey={t("team_2b")} /></div>
                  <div className='item_btn'>
                    <a href="https://www.linkedin.com/in/ewa-jakubowska-khs/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>
                        <FaLinkedin size={24}/>
                        <span>LINKEDIN</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>  
            <Transition in={expandedItemId === 2 || isWideScreen} timeout={duration}>
              {state => (
                <div className='team-grid-mobile'>
                  <div className='item_lead' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_2a")} /></div>
                  <div className='item_text' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_2b")} /></div>
                  <div style={expandedItemId === 2 ? {marginBottom: '20px'} : {marginBottom: '-20px'}}>
                    <div className='item_btn' style={{ ...defaultStyle, ...transitionStyles[state] }}>
                      <a href="https://www.linkedin.com/in/ewa-jakubowska-khs/" target="_blank" rel="noopener noreferrer" >
                        <button className='btn'>
                          <FaLinkedin size={24}/>
                          <span>LINKEDIN</span>
                        </button>
                      </a>
                    </div>
                  </div>

                </div>
              )}
            </Transition>        



            <div onClick={() => toggleExpand(3)} className='team_item'>
              <div className='photo'>
                <img className='pic' src={marcin} alt='' />
              </div>
              <div className='team_item_content'>
                <img className='icon' src={icon3} alt='' />
                <div className='item_title'>
                  Marcin Kalinowski
                  <small>Strategy Director</small>
                </div>
                <div className='team-grid'>
                  <div className='item_lead'><Trans i18nKey={t("team_3a")} /></div>
                  <div className='item_text'><Trans i18nKey={t("team_3b")} /></div>
                  <div className='item_btn'>
                    <a href="https://www.linkedin.com/in/marcinkalinowski/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>
                        <FaLinkedin size={24}/>
                        <span>LINKEDIN</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>   
            <Transition in={expandedItemId === 3 || isWideScreen} timeout={duration}>
              {state => (
                <div className='team-grid-mobile' style={{marginBottom: '20px'}}>
                  <div className='item_lead' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_3a")} /></div>
                  <div className='item_text' style={{ ...defaultStyle, ...transitionStyles[state] }}><Trans i18nKey={t("team_3b")} /></div>
                  <div className='item_btn' style={{ ...defaultStyle, ...transitionStyles[state] }}>
                    <a href="https://www.linkedin.com/in/marcinkalinowski/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>
                        <FaLinkedin size={24}/>
                        <span>LINKEDIN</span>
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </Transition>         



        </div>
      </div>
    </div>
  )
}

export default Team
