import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 2px solid #00FFC4 !important;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0px;
  border-radius: 3px;
  margin-top: 2px;
  display: none !important;
`;

const StyledCheckbox = styled.div`
    display: flex;
    width: 23px;
    height: 23px;
    border-radius: 3px;
    transition: all 150ms;
    border: 2px solid #00FFC4;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    background: transparent;
    align-items: center;
    justify-content: center;
    line-height: 0;

  ${HiddenCheckbox}:focus + & {

  }

  ${HiddenCheckbox}:hover + & {

  }

  ${props =>
    props.checked &&
    `
      background: transparent;
    `}
`;

const Checkbox = ({ className, checked, ...props }) => (
  <div style={{marginTop: '-4px'}}>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        {checked && <div><FaCheck color='#00FFC4' size={15}/></div>}
      </StyledCheckbox>
    </CheckboxContainer>    
  </div>

);

export default Checkbox;