import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom';
import icon1 from './img/icon1.png'
import lirene from './img/lirene.jpg'
import tarczynski2 from './img/tarczynski2.jpg'
import tarczynski1 from './img/tarczynski1.jpg'
import kramp from './img/kramp.jpg'
import ikea from './img/ikea.jpg'
import vw from './img/vw.jpg'
import btn from './img/btn.png'
import i18next from 'i18next';

function CaseStudies({isWideScreen, t}) {
  const divMain = useRef(null);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [bulletFontSize, setBulletFontSize] = useState('clamp(20px, 3vw, 41px)');

  useLayoutEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          if (divMain.current) {
            setHeight(divMain.current.clientHeight);
          }
        }
      }
    });

    if (divMain.current) {
      setHeight(divMain.current.clientHeight);
      observer.observe(divMain.current, { attributes: true, childList: true, subtree: true });
    }

    return () => {
      if (divMain.current) {
        observer.disconnect();
      }
    };
  }, [window.innerWidth]);

  const Item = ({img, title, text, maxTextWidth, url}) => {
    return (
      <div onClick={()=>navigate(url)} className='casesturies_item'>
        <div className='item_img'>
          <img src={img} alt=''/>
        </div>
        <div className='item_content'>
          <div className='item_content_title'>
            {title}
          </div>
          <div className='item_content_txt' style={maxTextWidth ? {maxWidth: '50%'} : {maxWidth: 'unset'}}>
            {text}
          </div>
          <div className='item_content_btn'>
            <button>
              <img src={btn} alt=''/>
            </button>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (i18next.language === 'en') {
      setBulletFontSize('clamp(11px, 3vw, 32px)');
    } else {
      setBulletFontSize('clamp(20px, 3vw, 41px)');
    }
  }, [i18next.language]);

  return (
    <>
      <div className='content1150_casestudies mobile'>
        <div className='casestudies'>

          <div style={{padding: '35px 0'}}>
            <div 
              className='casestudies_lead'
            >
              <span>{t("cs_item_hero")}</span>
              <span 
                style={
                  !isWideScreen 
                  ? {marginTop: '-3px'} 
                  : {marginTop: '0px'}
                }>
                {t('cs_item_hero2')} {!isWideScreen && <br/>}{t('cs_item_hero3')}
              </span>
            </div>
            <div 
              className='casestudies_bullets'
            >
              <div className='casestudies_bullets_main' ref={divMain}>
                <div className='blink'></div>
                <div className='casestudies_bullets_main_content'>
                  <div className='casestudies_bullets_main_points'>
                    <div className='casestudies_bullets_main_points_row'>
                      {/* <img src={bullet} alt=''/> */}
                      <div 
                        className='bullet_title'
                        style={{fontSize: bulletFontSize}}
                      >
                        {t('cs_item_text')} {!isWideScreen ? (<br/>) : ''} 
                        <span className='green'>
                          {t('cs_item_text2')}
                        </span>
                      </div>                  
                    </div>
                    <a 
                      href='#contact' 
                      className='btn' 
                      style={{textDecoration: 'none', position: 'relative'}}
                    >
                      {t('cs_item_btn')}
                    </a>
                  </div>          
                </div>
              </div>
              <div className='casestudies_bullets_shadow' style={{height: height || 0}}></div>
            </div>          
          </div>
        </div>
      </div>  

      <div className='content1150_casestudies'>
        <div className='casestudies'>


          <div 
            className='casestudies_title'
          >
            <div id='cs' className='cs_holder'></div>
            <div
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            ><img src={icon1} alt='' className='casestudies_icon'/></div>
            <div
              data-aos="fade-up" 
              data-aos-delay="200" 
              
            >{t('cs_item_title')}</div>
          </div>
          <div 
            className='info'
          >
            {t('cs_item_lead')}:
          </div>
          <div 
            className='casestudies_grid'
          >

              <Item
                img={lirene}
                title={'Lirene'}
                text={t('cs_item_1')}
                maxTextWidth={window.innerWidth > 600 ? true : false}
                url={'/lirene'}
              />

              <Item
                img={tarczynski2}
                title={'Tarczyński'}
                text={t('cs_item_2')}
                url={'/tarczynski-poranki'}
              />

              <Item
                img={tarczynski1}
                title={'Tarczyński'}
                text={t('cs_item_3')}
                url={'/tarczynski'}
              />

              <Item
                img={kramp}
                title={'Kramp'}
                text={t('cs_item_4')}
                url={'/kramp'}
              />

              <Item
                img={ikea}
                title={'IKEA'}
                text={t('cs_item_5')}
                url={'/ikea'}
              />

              <Item
                img={vw}
                title={'Volkswagen Home'}
                text={t('cs_item_6')}
                url={'/vw'}
              />

          </div>
        </div>
      </div>    
    </>

  );
}

export default CaseStudies;
