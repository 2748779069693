import React, { useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web'
import './style.css'
import { Trans } from 'react-i18next';

function PageEffects({bg, items, max, t}) {
  const [{ scrollY }, set] = useSpring(() => ({ scrollY: 0 }))

  const handleScroll = () => {
    set({ scrollY: window.scrollY })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  
  const Item = ({icon, title}) => {
    return (
      <div className='effect-point' style={{maxWidth: `${max}px` || 'unset'}}>
        <img src={icon} data-aos-delay="200" data-aos="fade-up"  alt=''/>
        <b 
          data-aos-delay="300" 
          data-aos="fade-up" 
        >
          <Trans i18nKey={title} components={{ span: <span /> }}/>
        </b>
      </div> 
    )
  }

  
  return (
    <animated.div 
      className='effect' 
      style={{
        backgroundImage: `url(${bg})`,  
        backgroundPositionY: scrollY.interpolate(y => `${-(y/8)}px`),
        backgroundPositionX: 'center' 
      }}>
      <div className='effect-container'>
        <h2 data-aos-delay="100" data-aos="fade-up" >{t("lirene_item_0")}</h2>
        <div className='effects-points-row'>
          {items.map((o,i) => {
            if(o.title !== ''){
              return <Item key={i} icon={o.icon} title={o.title}/>
            }
            
          })}         
        </div>

      </div>
    </animated.div>
  )
}

export default PageEffects