import React from 'react'
import { useNavigate } from 'react-router-dom';
import './style.css'

function PageFooter({t}) {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path, { replace: true });
    if (path.includes('#')) {
      const id = path.split('#')[1];
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }, 0);
    }
  };

  return (
    <div className='page-footer'>
      <div>
        <button onClick={() => handleNavigate('/#cs')} className='btn btn-normal btn_withe-bg'>
          {t("footer_page_btn_1")}
        </button>
      </div>
      <div>
        <button onClick={() => handleNavigate('/#contact')} className='btn btn-dark btn_withe-bg'>
        {t("footer_page_btn_2")}
        </button>
      </div>
    </div>
  )
}

export default PageFooter