import React, { useEffect } from 'react';
import { useSpring, animated, useScroll } from '@react-spring/web';
import Navigation from '../../components/navigation/navigation';
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import './styles.css';
import logo from './img/logo.png';
import bg from './img/effects_bg.jpg';
import icon1 from './img/icon1.png';
import icon2 from './img/icon2.png';
import icon3 from './img/icon3.png';
import icon4 from './img/icon4.png';
import bullet from './img/bullet1.png';
import bullet2 from './img/bullet2.png';
import pic from './img/pic.png';
import cube from './img/cube.png';
import logo_mobile from './img/logo_mobile.png'
import { Trans } from 'react-i18next';

const VW = ({isWideScreen, t, wideScreen}) => {
  const items = [
    {
      icon: icon1,
      title: t("vw_item_1"),
    },
    {
      icon: icon2,
      title: t("vw_item_2"),
    },
    {
      icon: icon3,
      title: t("vw_item_3"),
    },
    {
      icon: icon4,
      title: t("vw_item_4"),
    },
  ];

  const [{ scrollY }, set] = useSpring(() => ({ scrollY: 0 }));

  const handleScroll = () => {
    set({ scrollY: window.scrollY });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const minScale = .5; // minimalna skala
  const maxScale = 1.4; // maksymalna skala

  const animationProps = {
    'data-aos': 'fade-up',
    'data-aos-easing': 'ease-out-cubic',
  };

  const { scrollYProgress } = useScroll();

  const scale = scrollYProgress.to({
    range: [0, 1], // Zakres wartości scrollYProgress (0-1 to 0% do 100% przewinięcia strony)
    output: [minScale, maxScale], // Zakres wartości skali od 0.5 do 1
    extrapolate: 'clamp' // Ograniczenie wartości do zakresu
  });

  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen} wideScreen={wideScreen}/>
      <div className='page'>
        <div className='cs_baner_vw'>
          <div className='cs_baner_grid'>
            <div className='cs_baner_container'>
              <div className='cs_baner_content'>
                <img className='logo_mobile' src={logo_mobile} alt=''/>
                <div className='cs_baner_content_txt' data-aos="fade-up" data-aos-delay="100" >
                  <h2>
                    <Trans i18nKey={t("vw_banner_1")}/>
                  </h2>
                  <img src={logo} alt='' />
                  <h5>
                    <Trans i18nKey={t("vw_banner_2")}/>
                  </h5>   
                </div>
                <div></div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className='cs_black_space'></div>
        <div className='cs_container_1150_vw'>
          <div className='challenge'>
            <h2 className='title' data-aos="fade-up" data-aos-delay="100" >
              {t("challenge")}
            </h2>
            <div className='cs_bullets'>
              <div className='cs_bullets_box' data-aos="fade-up" data-aos-delay="200" >
                <img src={bullet} alt='' />
                <Trans i18nKey={t("vw_bullet_1")}/>  
                </div>
              <div className='cs_bullets_box' data-aos="fade-up" data-aos-delay="300" >
                <img src={bullet} alt='' />
                <Trans i18nKey={t("vw_bullet_2")}/>
              </div>
            </div>
          </div>
        </div>
        <animated.div className='strategy' style={{ backgroundPositionY: scrollY.interpolate(y => `${-(y / 5)}px`) }}>
          <div className='strategy_container'>
            <div className='cube'>
              <animated.img 
                src={cube} 
                alt=''
                style={{ transform: scale.to(s => `scale(${s})`), transformOrigin: 'right center' }}
              />
            </div>
            <div className='strategy_title'>
              <span data-aos-delay="100" {...animationProps}>
                {t("STRATEGIA")}
              </span>
            </div>
            <div className='strategy-lead' data-aos-delay="200" {...animationProps}>
              <Trans i18nKey={t("vw_strategy")} components={{ b: <strong /> }}/>
            </div>
          </div>
        </animated.div>
        <div className='cs_container_1150_vw'>
          <div className='realization'>
            <div className='realization-points'>
              <div className='point-column'>
                <h2 className='title' data-aos="fade-up" >
                  {t("wedid")}
                </h2>
                <div className='point' data-aos-delay="200" {...animationProps}>
                  <img className='point-bullet' src={bullet2} alt='' />
                  <div>
                    <Trans i18nKey={t("vw_point_1")}/>
                  </div>
                </div>
                <div className='point' data-aos-delay="300" {...animationProps}>
                  <img className='point-bullet' src={bullet2} alt='' />
                  <div>
                    <Trans i18nKey={t("vw_point_2")}/>
                  </div>
                </div>
                <div className='point' data-aos-delay="400" {...animationProps}>
                  <img className='point-bullet' src={bullet2} alt='' />
                  <div>
                    <Trans i18nKey={t("vw_point_3")}/>
                  </div>
                </div>
              </div>
              <div className='simple-flex'>
                <img src={pic} alt='' data-aos-delay="700" {...animationProps} />
              </div>
            </div>
          </div>
        </div>
        <PageEffects bg={bg} items={items} t={t}/>
        <PageFooter t={t}/>
      </div>
    </>
  );
};

export default VW;
