import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import icon from './img/icon.png'
import { Trans } from 'react-i18next';

const duration = 300;

const defaultStyle = {
  transition: `max-height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  maxHeight: 0,
  opacity: 0,
  overflow: 'hidden',
};

const transitionStyles = {
  entering: { maxHeight: '100px', opacity: 1 },
  entered: { maxHeight: '100px', opacity: 1 },
  exiting: { maxHeight: 0, opacity: 0 },
  exited: { maxHeight: 0, opacity: 0 },
};

const SolutionsItem = ({ id, title, textBefore, textAfter, isWideScreen }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    !isWideScreen && setIsExpanded(!isExpanded);
  };

  return (
    <div className='solutions_option_item'>
      <div className='options_point'>
        <img src={icon} onClick={toggleExpand} alt='' />
      </div>
      <span className='options_title' onClick={toggleExpand}><Trans i18nKey={title} /></span>
      <span className='options_text' onClick={toggleExpand}>
        <div className='rowfix'>
          {textBefore} {isWideScreen && textAfter}
          {!isWideScreen && !isExpanded &&
            <button onClick={toggleExpand}>
              {isExpanded ? <IoArrowUpCircleOutline size={20}/> : <IoArrowForwardCircleOutline size={20} />}
            </button>      
          }          
        </div>
        <Transition in={isExpanded} timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}>
              {textAfter}
            </div>
          )}
        </Transition>
      </span>

    </div>
  );
};

export default SolutionsItem;
