
import React, { useState, Suspense, lazy } from 'react';
import '../../styles/app.css'
import Header from "../../sections/header/header";
// import Navigation from '../../sections/navigation/navigation';
import Navigation from '../../components/navigation/navigation';
import Needs from '../../sections/needs/needs';
import Working from '../../sections/dzialamy/working';
import Solutions from '../../sections/rozwiazania/solutions';
import Products from '../../sections/produkty/products';
import CaseStudies from '../../sections/casestudies/casestudies';
import Team from '../../sections/team/team';
import Recommend from '../../sections/polecaja/recommend'
import Contact from '../../sections/contact/contact';
import Awards from '../../sections/awards/awards';
import Footer from '../../sections/footer/footer';


const LazyNavigation = lazy(() => import('../../components/navigation/navigation'));

function App({ isWideScreen, isMobile, isLandscape, wideScreen, t }) {
  const [scroll, setScroll] = useState(null)

  return (
    <>
      <Suspense fallback={''}>
        <LazyNavigation 
          scroll={scroll}
          isWideScreen={isWideScreen}
          wideScreen={wideScreen}
        />
      </Suspense>
      {/* <Navigation 
        scroll={scroll}
        isWideScreen={isWideScreen}
      /> */}
      <Header 
        setScroll={setScroll} 
        isWideScreen={isWideScreen}
        isMobile={isMobile}
        isLandscape={isLandscape}
        wideScreen={wideScreen}
        t={t}
      />
      <Needs isWideScreen={isWideScreen} t={t}/>
      <Working t={t}/>
      <Solutions isWideScreen={isWideScreen} t={t}/>
      <Products isWideScreen={isWideScreen} t={t}/>
      <CaseStudies isWideScreen={isWideScreen} t={t}/>
      <Team isWideScreen={isWideScreen} t={t}/>
      <Recommend isWideScreen={isWideScreen} t={t}/>
      <Contact t={t}/>
      <Awards t={t}/>
      <Footer t={t}/>
    </>
  );
}

export default App;
