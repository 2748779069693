import React, { useEffect, useState, useRef } from 'react'
import AOS from 'aos';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import './style.css'
import layer1 from './img/DINKS01.png'
import layer2 from './img/DINKS02.png'
import layer3 from './img/DINKS03.png'
import kameleon from './img/kameleon.png'

function Header({
  isMobile,
  isLandscape,
  wideScreen,
  isWideScreen,
  t
}) {
  const navigate = useNavigate();
  const [{ scrollY }, setScrollY] = useSpring(() => ({ scrollY: 0 }))
  const [showLayer1, setShowLayer1] = useState(false);
  const [showLayer2, setShowLayer2] = useState(false);
  const [showLayer3, setShowLayer3] = useState(false);
  const [showKameleon, setShowKameleon] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imageRef = useRef(null);
  const textRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [widthText, setWidthText] = useState(0);

  const updateDimensions = () => {
    if (imageRef.current || textRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      const height = imageRef.current.getBoundingClientRect().height;
      const widthText = textRef.current.getBoundingClientRect().width;
      setWidth(width);
      setHeight(height);
      setWidthText(widthText)
    }
  };

  const handleImageLoad = () => {
    updateDimensions();
    setIsImageLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (imageRef.current) {
        const width = imageRef.current.getBoundingClientRect().width;
        const height = imageRef.current.getBoundingClientRect().height;
        const widthText = textRef.current.getBoundingClientRect().width;
        setWidthText(widthText)
        setWidth(width);
        setHeight(height);
      }
    }, 1000)
  }, [showKameleon]);

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const [props_mask1, set_mask1] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const [props_mask2, set_mask2] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const [props_mask3, set_mask3] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans_mask1 = (x, y) => `translate3d(${x / -90}px,${y / -110}px,0)`;
  const trans_mask2 = (x, y) => `translate3d(${x / -90}px,${y / -110}px,0)`;
  const trans_mask3 = (x, y) => `translate3d(${x / -100}px,${y / -110}px,0)`;

  const handleScroll = () => {
    const newScrollY = window.scrollY <= 250 ? window.scrollY : 250;
    setScrollY({ scrollY: newScrollY });
  }

  const handleNavigate = (path) => {
    navigate(path, { replace: true });
    if (path.includes('#')) {
      const id = path.split('#')[1];
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }, 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    AOS.refresh();
  }, []);

  useEffect(() => {
    setTimeout(() => setShowLayer1(true), 100);
    setTimeout(() => setShowLayer2(true), 200);
    setTimeout(() => setShowLayer3(true), 250);
    setTimeout(() => setShowKameleon(true), 300);
  }, []);

  const fadeIn = useSpring({ opacity: showLayer1 ? 1 : 0, config: { duration: 300 } });
  const fadeIn2 = useSpring({ opacity: showLayer2 ? 1 : 0, config: { duration: 300 } });
  const fadeIn3 = useSpring({ opacity: showLayer3 ? 1 : 0, config: { duration: 300 } });
  const fadeInKameleon = useSpring({ opacity: showKameleon ? 1 : 0, config: { duration: 300 } });

  return (
    <div className='header'>
      {showLayer1 && (
        <animated.div style={{ ...fadeIn, transform: props_mask1.xy.interpolate(trans_mask1) }} >
          <animated.img 
            className='layer1' 
            src={layer1} 
            style={isWideScreen ? { 
              transform: scrollY.interpolate(y => `translate(${y / 25}px, ${y / 15}px)`), 
              width: '760px', 
              height: 'auto' 
            } : {
              width: '760px', 
              height: 'auto' 
            }}
            alt=''/>          
        </animated.div>
      )}

      {showLayer2 && (
        <animated.div style={{ ...fadeIn2, transform: props_mask2.xy.interpolate(trans_mask2) }}>
          <animated.img 
            className='layer2' 
            src={layer2} 
            style={{transform: scrollY.interpolate(y => `translate(${-(y / 25)}px, ${-(y / 25)}px)`)}}
            alt=''
            />
        </animated.div>
      )}

      {showLayer3 && (
        <animated.div style={{ ...fadeIn3, transform: props_mask3.xy.interpolate(trans_mask3) }}>
          <animated.img 
            className='layer3' 
            src={layer3} 
            style={
              isWideScreen ?
              { transform: scrollY.interpolate(y => `translate(${(y / 25)}px, ${-(y / 25)}px)`) }
              :
              { display: 'inline' }
            }
            alt=''
          />
        </animated.div>
      )}

      <span data-aos="fade-up" data-aos-delay="0" ></span>
      <div className='container'>
        <div className='banner' style={
            wideScreen <= 650 ? {gridTemplateRows: `${height > 0 ? height/1.2 + 'px' : '.853fr'} 1fr`} : {gridTemplateRows: `1fr`}
          }>
          <div className='text' ref={textRef}>
            <div 
              className='banner_txt'
              onMouseMove={({ clientX: x, clientY: y }) => {
                set({ xy: calc(x, y) })
                set_mask1({ xy: calc(x, y) })
                set_mask2({ xy: calc(x, y*(-1)) })
                set_mask3({ xy: calc(x, y*(-1)) })
              }}
            >
              <h4 
                data-aos="fade" 
                data-aos-delay="400" 
                style={isMobile && isLandscape ? {fontSize: '15px', marginTop: '20px'} : {fontSize: 'auto'}}
              >{t('header_h4')}</h4>
              <h2 
                data-aos="fade-up" 
                data-aos-delay="100" 
                style={isMobile && isLandscape ? {fontSize: '45px'} : {fontSize: 'auto'}}
              >
                  CRAFTED FOR 
                  <span 
                    className='green'
                    style={isMobile && isLandscape ? {fontSize: '45px'} : {fontSize: 'auto'}}
                  >
                    GROWTH
                  </span>
                </h2>
              <h5
                data-aos="fade" 
                data-aos-delay="600" 
                style={isMobile && isLandscape ? {fontSize: '12px', marginTop: '5px'} : {fontSize: 'auto'}}
                >
                  {t('header_p')}
                  <span>
                    <span></span>
                    <button
                      className='btn'
                      onClick={()=>handleNavigate('#contact')}
                    >{t('contact_us')}
                    </button>
                  </span> 
              </h5>
            </div>
          </div>
          <div className='empty'></div>
        </div>
        <div 
          className='banner-cameleon'
          style={
            wideScreen >= 650 ? {
              gridTemplateColumns: `${widthText > 0 ? wideScreen > 1200 ? widthText/1.4 + 'px' : (widthText/1.4 + 40) + 'px' : '1fr'} 1fr`,
              marginTop: '-28px'
            } 
            :
            {
              gridTemplateColumns: `1fr`
            }
          } 
        >
          <div></div>
          <div className='graph'>
            {showKameleon && (
              <animated.img 
                ref={imageRef}
                className='cameleon' 
                src={kameleon}
                onLoad={handleImageLoad}
                style={{ ...fadeInKameleon, transform: scrollY.interpolate(y => `translate(0, ${-(y / 8)}px)`) }}
                alt=''
              /> 
            )}
          </div>
        </div>
      </div> 
    </div>
  );
}

export default Header;
