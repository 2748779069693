import React, { useState } from 'react';
import StickyNavbar from 'react-sticky-navbar';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css';
import logo from './img/logo.png';
import sign from './img/menu-sign.png';
import lirene from '../../sections/casestudies/img/lirene.png'
import tarczynski2 from '../../sections/casestudies/img/tarczynski2.png'
import tarczynski1 from '../../sections/casestudies/img/tarczynski1.png'
import kramp from '../../sections/casestudies/img/kramp.png'
import ikea from '../../sections/casestudies/img/ikea.png'
import vw from '../../sections/casestudies/img/vw.jpg'
import Social from '../social/social';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../language/languageSwitch';

function Navigation({ scroll, isPage, isWideScreen, wideScreen }) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisibleScrolling, setDropdownVisibleScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleMouseEnterScrolling = () => {
    setDropdownVisibleScrolling(true);
  };

  const handleMouseLeaveScrolling = () => {
    setDropdownVisibleScrolling(false);
  };


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path) => {
    navigate(path, { replace: true });
    setIsOpen(false)
    if (path.includes('#')) {
      const id = path.split('#')[1];
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }, 0);
    }
  };


  return (
    <>
      <StickyNavbar
        // showOnTop={window.innerWidth > 600 ? false : true}
        showOnTop={!isWideScreen ? true : false}
        // showOnTop={true}
        showOnScrollDown={true}
        duration={1000}
      >
        <div className='navigation-box-small'>
          <div className='navigation'>

            <button 
              style={isWideScreen ? {top: '10px'}:{top: '19px'}}
              className={`burger ${isOpen ? 'open' : ''}`} onClick={handleClick}>
              <div className={`stick stick-1 ${isOpen ? 'open' : 'close'}`}></div>
              <div className={`stick stick-2 ${isOpen ? 'open' : 'close'}`}></div>
              <div className={`stick stick-3 ${isOpen ? 'open' : 'close'}`}></div>
            </button>

            <ul 
                className={`dropdown-list ${isOpen ? 'visible' : ''}`}
                style={isOpen ? {pointerEvents: 'auto'}:{pointerEvents: 'none'}}
              >
                <li className='mobile-logo'>
                  <img onClick={()=>navigate('/')} className='logo' src={logo} alt='' />
                </li>
                <li style={{paddingTop: '1vh'}}>
                  <a onClick={() => handleNavigate('/#solutions')} style={{padding: 0, fontSize: '17px', fontWeight: 600}}>{t('solutions')}</a>
                </li>
                <li
                  onMouseEnter={handleMouseEnterScrolling}
                  onMouseLeave={handleMouseLeaveScrolling}
                  className='submenu'
                >
                  <a onClick={() => handleNavigate('/#cs')} style={{padding: 0, fontSize: '17px', fontWeight: 600}}>
                    Case studies
                  </a>

                  {isWideScreen &&
                    <ul 
                      className={`dropdown-list ${isDropdownVisibleScrolling ? 'visible' : ''}`}
                      style={isDropdownVisibleScrolling ? {pointerEvents: 'auto'}:{pointerEvents: 'none'}}
                    >
                      <li>
                        <span
                          className='sign'
                          style={isDropdownVisibleScrolling ? { top: '-24px', opacity: .7 } : { top: '-15px', opacity: 0 }}
                        ></span>

                        <button 
                          className={location.pathname === '/lirene' && 'active'} 
                          onClick={()=>navigate('/lirene')}
                        >
                          <img src={lirene} alt='' width={170}/>
                          Lirene
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>navigate('/tarczynski-poranki')}
                          className={location.pathname === '/tarczynski-poranki' && 'active'}
                        >
                          <img src={tarczynski2} alt='' width={170}/>
                          Tarczyński
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>navigate('/tarczynski')}
                          className={location.pathname === '/tarczynski' && 'active'}
                        >
                          <img src={tarczynski1} alt='' width={170}/>
                          Tarczyński
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>navigate('/kramp')}
                          className={location.pathname === '/kramp' && 'active'}
                        >
                          <img src={kramp} alt='' width={170}/>
                          KRAMP
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>navigate('/ikea')}
                          className={location.pathname === '/ikea' && 'active'}
                        >
                          <img src={ikea} alt='' width={170}/>
                          IKEA
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>navigate('/vw')}
                          className={location.pathname === '/vw' && 'active'}
                        >
                          <img src={vw} alt='' width={170}/>
                          Volkswagen
                        </button>
                      </li>
                    </ul>
                  }

                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/lirene')}>Lirene</a>
                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/tarczynski-poranki')}>Tarczyński</a>
                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/tarczynski')}>Tarczyński</a>
                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/kramp')}>KRAMP</a>
                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/ikea')}>IKEA</a>
                </li>
                <li className='sublink'>
                  <a onClick={()=>navigate('/vw')}>Volkswagen Home</a>
                </li>
                <li style={{paddingTop: '1vh'}}>
                  <a onClick={() => handleNavigate('/#contact')} style={{padding: 0, fontSize: '17px', fontWeight: 600}}>{t('contact')}</a>
                </li>
                <li className='nav-social'>
                  <Social/>
                </li>
                <li className='small'>
                  <LanguageSwitch
                    checked={checked}
                    setChecked={setChecked}
                    wideScreen={wideScreen}
                  />   
                </li>
            </ul>

          </div>
        </div>  
      </StickyNavbar>

      <div className={`navigation-box ${isPage ? 'isPage' : ''}`} style={scroll > 30 ? { opacity: 0 } : { opacity: 1, zIndex: 3 }}>
        <div className='navigation'>
         <img src={logo} onClick={()=>navigate('/')} alt='' style={!isPage ? {maxWidth: '55px', top: '0px'} : {maxWidth: '55px', top: 0}}/>
          <ul>
            <li>
              <span onClick={() => handleNavigate('/#solutions')}>{t('solutions')}</span>
            </li>
            <li
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className='submenu'
            >
              <a href='#cs' style={isDropdownVisible ? { color: '#00FFC4' } : { color: '#fff' }}>
                Case studies
              </a>
              <ul 
                className={`dropdown-list ${isDropdownVisible ? 'visible' : ''}`}
                style={isDropdownVisible ? {pointerEvents: 'auto'}:{pointerEvents: 'none'}}
              >
                <li>
                  <span
                    className='sign'
                    style={isDropdownVisible ? { top: '-24px', opacity: .7 } : { top: '-15px', opacity: 0 }}
                  >
                    <img src={sign} alt=''/>
                  </span>

                  <button 
                    className={location.pathname === '/lirene' && 'active'} 
                    onClick={()=>navigate('/lirene')}
                  >
                    <img src={lirene} alt='' width={170}/>
                    Lirene
                  </button>
                </li>
                <li>
                  <button 
                    onClick={()=>navigate('/tarczynski-poranki')}
                    className={location.pathname === '/tarczynski-poranki' && 'active'}
                  >
                    <img src={tarczynski2} alt='' width={170}/>
                    Tarczyński
                  </button>
                </li>
                <li>
                  <button 
                    onClick={()=>navigate('/tarczynski')}
                    className={location.pathname === '/tarczynski' && 'active'}
                  >
                    <img src={tarczynski1} alt='' width={170}/>
                    Tarczyński
                  </button>
                </li>
                <li>
                  <button 
                    onClick={()=>navigate('/kramp')}
                    className={location.pathname === '/kramp' && 'active'}
                  >
                    <img src={kramp} alt='' width={170}/>
                    KRAMP
                  </button>
                </li>
                <li>
                  <button 
                    onClick={()=>navigate('/ikea')}
                    className={location.pathname === '/ikea' && 'active'}
                  >
                    <img src={ikea} alt='' width={170}/>
                    IKEA
                  </button>
                </li>
                <li>
                  <button 
                    onClick={()=>navigate('/vw')}
                    className={location.pathname === '/vw' && 'active'}
                  >
                    <img src={vw} alt='' width={170}/>
                    Volkswagen
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <span onClick={() => handleNavigate('/#contact')}>{t('contact')}</span>
            </li>
          </ul>
          <div className='social-position'>
            {isWideScreen && <Social/> }       
          </div>
          <LanguageSwitch
            checked={checked}
            setChecked={setChecked}
            wideScreen={wideScreen}
          />   
        </div>
      </div>
    </>
  );
}

export default Navigation;
