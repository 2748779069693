import React from 'react';
import Navigation from '../../components/navigation/navigation';
import './styles.css'
import logo from './img/logo.png'
import bullet from './img/bullet.png'
import bullet2 from './img/bullet2.png'
import bg from './img/effect_bg.png'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import { Trans } from 'react-i18next';

const Lirene = ({isWideScreen, t, wideScreen}) => {
  const items = [
    {
      icon: icon1,
      title: t('lirene_item_1')
    },
    {
      icon: icon2,
      title: t('lirene_item_2')
    },
    {
      icon: icon3,
      title: t('lirene_item_3')
    }
  ]

  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen} wideScreen={wideScreen}/>
      <div className='page'>
        <div className='cs_baner_lirene'>
          <div className='cs_baner_container'>
            <img 
              src={logo} 
              alt=''
              data-aos="fade-up"data-aos-delay="100"
            />
            <h2 data-aos="fade-up"data-aos-delay="200">
              <Trans i18nKey={t("lirene_baner_1")}/>
            </h2>
            <h5 data-aos="fade-up"data-aos-delay="300">
              <Trans i18nKey={t("lirene_baner_2")}/>
            </h5>            
          </div>
        </div>
        <div className='cs_black_space'></div>
        <div className='cs_container_1150'>

          <div className='challenge'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              <Trans i18nKey={t("lirene_title_1")}/>
            </h2>
            <div className='cs_bullets'>

              <div 
                className='cs_bullets_box'
                data-aos="fade-up" 
                data-aos-delay="200" 
                
              >
                <img src={bullet} alt=''/>
                <Trans i18nKey={t("lirene_bullet_1")}/>
              </div>
              <div 
                className='cs_bullets_box'
                data-aos="fade-up" 
                data-aos-delay="300" 
                
              >
                <img src={bullet} alt=''/>
                <Trans i18nKey={t("lirene_bullet_2")}/>
              </div>
            </div>            
          </div>

          <div className='realization'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              <Trans i18nKey={t("lirene_title_2")}/>
            </h2>

            <div className='realization-points'>

              <div className='simple-flex'>
                <div className='point' data-aos-delay="200" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div className='points-rows'>
                    <span><Trans i18nKey={t("lirene_check_1")}/>:</span>
                    <b className='hero'><Trans i18nKey={t("lirene_check_2a")}/></b>                  
                  </div>
                </div>

                <div className='point' data-aos-delay="300" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div className='realization-points-points'>
                    <Trans i18nKey={t("lirene_check_2b")}/>:
                    <div className='subpoints'>
                      <p>
                        <Trans i18nKey={t("lirene_check_2c")} components={{ b: <strong /> }}/>
                      </p>
                      <p>
                        <Trans i18nKey={t("lirene_check_2d")} components={{ b: <strong /> }}/>
                      </p>                     
                    </div>
                  
                  </div>
                </div>                
              </div>


              <div className='point' data-aos-delay="400" data-aos="fade-up" >
                <img className='point-bullet' src={bullet2} alt=''/>
                <div>
                  <b><Trans i18nKey={t("lirene_check_3a")}/>:</b><br/>
                  <Trans i18nKey={t("lirene__check_3b")}/> 
                  <br/><br/>
                  <Trans i18nKey={t("lirene__check_3c")}/> 
                </div>
              </div>
            </div>
          </div>

        </div>
        <PageEffects bg={bg} items={items} t={t}/>
        <PageFooter t={t}/>
      </div>      
    </>

  );
};

export default Lirene;