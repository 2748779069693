import React from 'react';
import Navigation from '../../components/navigation/navigation';
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import './styles.css'
import logo from './img/logo.png'
import bg from './img/effects_bg.jpg'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import bullet1 from './img/bullet1.png'
import bullet2 from './img/bullet2.png'
import pic1 from './img/pic1.png'
import pic2 from './img/pic2.png'
import { Trans } from 'react-i18next';

const Tarczynski = ({isWideScreen, t, wideScreen}) => {
  const items = [
    {
      icon: icon1,
      title: t('tarczynski2_item_1')
    },
    {
      icon: icon2,
      title: t('tarczynski2_item_2')
    },
    {
      icon: icon3,
      title: t('tarczynski2_item_3')
    }
  ]

  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen} wideScreen={wideScreen}/>
      <div className='page'>
        <div className='cs_baner_tarczynski'>
          <img
            src={logo} 
            alt=''
          />
          <div className='cs_baner_container'>
            <h2 data-aos-delay="100" data-aos="fade-up" >
              <Trans i18nKey={t("tarczynski2_banner_1")}/>
            </h2>
            <h5 data-aos-delay="200" data-aos="fade-up" >
              <Trans i18nKey={t("tarczynski2_banner_2")}/>
            </h5>            
          </div>
        </div>
        <div className='cs_black_space'></div>

        <div className='cs_container_1150_tarczynski'>

          <div className='challenge'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              {t('challenge')}
            </h2>
            <div className='cs_poranek_row'>
              <div className='cs_bullets'>
                <div 
                  className='cs_bullets_box'
                  data-aos="fade-up" 
                  data-aos-delay="200" 
                  
                >
                  <img src={bullet1} alt=''/>
                  <Trans i18nKey={t("tarczynski2_bullet")}/>
                </div>
              </div>               
            </div>
          
          </div>

          <div className='realization'>
            <div className='realization-points'>
              <div className='points_column'>
                <h2 
                  className='title'
                  data-aos="fade-up" 
                  data-aos-delay="100" 
                  
                >
                  {t('wedid')}
                </h2>
                <div className='point' data-aos-delay="400" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <b><Trans i18nKey={t("tarczynski2_1")}/></b>          
                  </div>
                </div>

                <div className='point' data-aos-delay="500" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski2_2")}/>
                  </div>
                </div>
              </div>
              <div className='baner_event'>
                <img style={{position: 'relative', top: '6px'}} src={pic1} alt='' data-aos="fade-up" data-aos-delay="300" />
              </div>
            </div>
            <div className='realization-points'>
              <div className='baner_event'>
                <img src={pic2} alt='' data-aos="fade-up" data-aos-delay="300" />
              </div>
              <div className='points_column right'>
                <div className='point' data-aos-delay="400" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski2_3")}/>
                  </div>
                </div>

                <div className='point' data-aos-delay="500" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski2_4")}/>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PageEffects bg={bg} items={items} t={t}/>
        <PageFooter t={t}/>
      </div>      
    </>

  );
};

export default Tarczynski;