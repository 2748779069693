import React, { useEffect } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import Cookies from 'js-cookie';
import pl from './img/pl.png'
import en from './img/en.png'

function LanguageSwitch({
  checked,
  setChecked,
  wideScreen
}) {
  const { i18n } = useTranslation();
  
  useEffect(() => {
    let currentLanguage = sessionStorage.getItem('i18nextLng');
    if (!currentLanguage) {
      currentLanguage = Cookies.get('i18nextLng') || 'pl';
    }
    setChecked(currentLanguage === 'pl');
  }, [setChecked]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    sessionStorage.setItem('i18nextLng', lng);
    Cookies.set('i18nextLng', lng, { expires: 365 });
  };

  const handleDivClick = () => {
    const nextChecked = !checked;
    setChecked(nextChecked);
    const language = nextChecked ? 'pl' : 'en';
    changeLanguage(language);
  };

  return (
    <div className='language-switch' onClick={handleDivClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <label style={{ marginRight: '10px', cursor: 'pointer' }}>
        {wideScreen > 650 ? (!checked ? 'POLSKI' : 'ENGLISH') : (!checked ? <img src={pl} alt='pl'/> : <img src={en} alt='en'/>)}
      </label>
      <Switch 
        onChange={e => wideScreen < 650 ? handleDivClick() : {}} // No action needed here since the div click handles it
        checked={checked} 
        offColor="#00FFC4"
        onColor="#00FFC4"
        offHandleColor="#fff"
        onHandleColor="#fff"
        height={17}
        width={29}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={17}
      />
    </div>
  );
}

export default LanguageSwitch;
