import React, { useLayoutEffect, useState } from 'react';
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import Navigation from '../../components/navigation/navigation';
import './styles.css'
import logo from './img/logo.png'
import bg from './img/effects_bg.jpg'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import bullet1 from './img/bullet1.png'
import bullet2 from './img/bullet2.png'
import p1 from './img/p1.png'
import p2 from './img/p2.png'
import p3 from './img/p3.png'
import baner_event from './img/baner_event.png'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import c1 from './img/carusella/c1.png'
import c2 from './img/carusella/c2.png'
import c3 from './img/carusella/c3.png'
import c4 from './img/carusella/c4.png'
import c5 from './img/carusella/c5.png'
import c6 from './img/carusella/c6.png'
import c7 from './img/carusella/c7.png'
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { Trans } from 'react-i18next';

const TarczynskiPoranki = ({isWideScreen, t, wideScreen}) => {

  const items = [
    {
      icon: icon1,
      title: t("tarczynski1_item_1")
    },
    {
      icon: icon2,
      title: t("tarczynski1_item_2")
    },
    {
      icon: icon3,
      title: t("tarczynski1_item_3")
    }
  ]
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateWindowWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);


  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen} wideScreen={wideScreen}/>
      <div className='page'>
        <div className='cs_baner_tarczynski_poranki'>
          <img
            src={logo} 
            className='logo'
            alt=''
          />
          <div className='cs_baner_container_tarczynski_poranki'>
            <div className='text_content' data-aos="fade-up"data-aos-delay="100">
              <h2 data-aos="fade-up"data-aos-delay="200">
                <Trans i18nKey={t("tarczynski1_banner_1")}/>
              </h2>
              <h5 data-aos="fade-up"data-aos-delay="300">
                <Trans i18nKey={t("tarczynski1_banner_2")}/>
              </h5> 
            </div>
          </div>
        </div>
        <div className='cs_black_space'></div>

        <div className='cs_container_1150_tarczynski_poranki'>

          <div className='challenge'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              <Trans i18nKey={t("tarczynski1_challenge")}/>
            </h2>
            <div className='cs_poranek_row'>
              <div className='cs_bullets'>

                <div 
                  className='cs_bullets_box'
                  data-aos="fade-up" 
                  data-aos-delay="200" 
                  
                >
                  <img src={bullet1} alt=''/>
                  <Trans i18nKey={t("tarczynski1_bullet")}/>
                </div>
              </div> 
              <div className='cs_poranki_img'>
                <img src={p1} alt='' data-aos="fade-up" data-aos-delay="300" />
                <img src={p2} alt='' data-aos="fade-up" data-aos-delay="400" /> 
                <img src={p3} alt='' data-aos="fade-up" data-aos-delay="500" /> 
              </div>               
            </div>
          
          </div>

          <div className='realization'>
            <h2 
              className='title'
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              <Trans i18nKey={t("tarczynski1_wedid")}/>
            </h2>

            <div className='realization-points'>
              <div className='baner_event'>
                <img src={baner_event} alt='' data-aos="fade-up" data-aos-delay="300" />
              </div>
              <div className='points_column'>
                <div className='point' data-aos-delay="400" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski1_point_1")} components={{ b: <strong /> }}/>            
                  </div>
                </div>

                <div className='point' data-aos-delay="500" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski1_point_2")} components={{ b: <strong /> }}/>
                  </div>
                </div>

                <div className='point' data-aos-delay="600" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski1_point_3")} components={{ b: <strong /> }}/>           
                  </div>
                </div>

                <div className='point' data-aos-delay="700" data-aos="fade-up" >
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("tarczynski1_point_4")} components={{ b: <strong /> }}/>              
                  </div>
                </div>
              </div>
            </div>

            <div 
              className='carusele_poranki' 
              style={{marginTop: '50px', marginRight: '1px'}}
              data-aos="fade-up" 
              data-aos-delay="100" 
              
            >
              <CarouselProvider
                visibleSlides={windowWidth < 800 ? windowWidth < 600 ? 1 : 2 : 3}
                totalSlides={7}
                step={1}
                naturalSlideWidth={409}
                naturalSlideHeight={230}
                interval={4000}
                isPlaying={true}
              >
                <Slider>
                  <Slide index={0} className={'carouselSlide'}><img src={c1} alt=''/></Slide>
                  <Slide index={1} className={'carouselSlide'}><img src={c2} alt=''/></Slide>
                  <Slide index={2} className={'carouselSlide'}><img src={c3} alt=''/></Slide>
                  <Slide index={4} className={'carouselSlide'}><img src={c4} alt=''/></Slide>
                  <Slide index={5} className={'carouselSlide'}><img src={c5} alt=''/></Slide>
                  <Slide index={6} className={'carouselSlide'}><img src={c6} alt=''/></Slide>
                  <Slide index={7} className={'carouselSlide'}><img src={c7} alt=''/></Slide>
                </Slider>
                <div className='carusele-btns' data-aos="fade-up" data-aos-delay="200" >
                  <ButtonBack className='btn'><GrPrevious /></ButtonBack>
                  <ButtonNext className='btn'><GrNext /></ButtonNext>                  
                </div>

              </CarouselProvider>
            </div>            

          </div>

        </div>

        <PageEffects bg={bg} items={items} t={t}/>
        <PageFooter t={t}/>
      </div>      
    </>

  );
};

export default TarczynskiPoranki;