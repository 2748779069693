import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Zmiana klasy na 'scroll-behavior-auto' w momencie uruchomienia hooka
    document.documentElement.classList.add("scroll-behavior-auto");
    
    // Przewinięcie do góry
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });

    return () => {
      // Z powrotem zmiana klasy na 'scroll-behavior-smooth' po zakończeniu pracy hooka
      document.documentElement.classList.remove("scroll-behavior-auto");
    };
  }, [location]);

  return children || null;
};

export default ScrollToTop;