import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
};

i18n
  .use(LanguageDetector) // użyj LanguageDetector do wykrywania języka przeglądarki
  .use(initReactI18next) // przekazuje i18n do react-i18next
  .init({
    resources,
    fallbackLng: 'pl', // język zapasowy
    interpolation: {
      escapeValue: false, // react już chroni przed XSS
    },
    detection: {
      // ustawienia detektora języka
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;