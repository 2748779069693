import React, { useRef, useLayoutEffect, useState } from 'react'
import './style.css'
import ispot_k from './img/ispot.svg'
import samsung_k from './img/samsung.svg'
import rtv_k from './img/rtv.svg'
import ikea_k from './img/ikea.svg'
import veolia_k from './img/veolia.svg'
import syngenta_k from './img/syngenta.svg'
import kramp_k from './img/kramp.svg'
import polcalc_k from './img/polcalc.svg'
import lafarge_k from './img/lafarge.svg'
import tarczyn_k from './img/tarczynski.svg'
import wedel_k from './img/wedel.svg'
import lirene_k from './img/lirene.svg'
import vw_k from './img/vw.svg'
import cupra_k from './img/cupra.svg'
import seat_k from './img/seat.svg'


function Working({t}) {
  const divMain = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          if (divMain.current) {
            setHeight(divMain.current.clientHeight);
          }
        }
      }
    });

    if (divMain.current) {
      setHeight(divMain.current.clientHeight);
      observer.observe(divMain.current, { attributes: true, childList: true, subtree: true });
    }

    return () => {
      if (divMain.current) {
        observer.disconnect();
      }
    };
  }, [window.innerWidth]);


  return (
    <div className='content1150_working'>
      <div className='working'>
        <div 
          className='working_title'
          data-aos="fade-up" 
          data-aos-delay="100" 
        >
          <span >
            {t('WE_WORK')}
          </span> 
          <span className='green'>
          {t('WITH_THE_BEST')}
          </span>
        </div>
        <div>
          <div className='working_box'>
            <div><img src={ispot_k} alt=''/></div>
            <div><img src={samsung_k} alt=''/></div>
            <div><img src={rtv_k} alt=''/></div>
            <div><img src={ikea_k} alt=''/></div>
            <div><img src={veolia_k} alt=''/></div>
            <div><img src={syngenta_k} alt=''/></div>
            <div><img src={kramp_k} alt=''/></div>
            <div><img src={polcalc_k} alt=''/></div>
            <div><img src={lafarge_k} alt=''/></div>
            <div><img src={tarczyn_k} alt=''/></div>
            <div><img src={wedel_k} alt=''/></div>
            <div><img src={lirene_k} alt=''/></div>
            <div><img src={vw_k} alt=''/></div>
            <div><img src={cupra_k} alt=''/></div>
            <div><img src={seat_k} alt=''/></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Working;
