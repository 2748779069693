import React, { useRef, useLayoutEffect, useState} from 'react';
import './style.css';
import icon1 from './img/icon1.png';
import icon2 from './img/icon2.png';
import SolutionsItem from './SolutionsItem';


function Solutions({isWideScreen, t}) {
  const divMain = useRef(null);
  const [height, setHeight] = useState(0);

  const items1 = [
    { 
      id: 1, 
      title: t('item11_title'), 
      textBefore: t('item11_textBefore'),
      textAfter: t('item11_textAfter'),
    },
    { 
      id: 2, 
      title: t('item12_title'), 
      textBefore: t('item12_textBefore'),
      textAfter: t('item12_textAfter'),
    },
    { 
      id: 3, 
      title: t('item13_title'), 
      textBefore: t('item13_textBefore'),
      textAfter: t('item13_textAfter'),
    }
  ];

  const items2 = [
    { 
      id: 4, 
      title: t('item21_title'), 
      textBefore: t('item21_textBefore'),
      textAfter: t('item21_textAfter'),
    },
    { 
      id: 5, 
      title: t('item22_title'), 
      textBefore: t('item22_textBefore'),
      textAfter: t('item22_textAfter'),
    },
    { 
      id: 6, 
      title: t('item23_title'), 
      textBefore: t('item23_textBefore'),
      textAfter: t('item23_textAfter'),
    }
  ];

  const items3 = [
    { 
      id: 7, 
      title: t('item31_title'), 
      textBefore: t('item31_textBefore'),
      textAfter: t('item31_textAfter'),
    },
    { 
      id: 8, 
      title: t('item32_title'), 
      textBefore: t('item32_textBefore'),
      textAfter: t('item32_textAfter'),
    },
    { 
      id: 9, 
      title: t('item33_title'), 
      textBefore: t('item33_textBefore'),
      textAfter: t('item33_textAfter'),
    }
  ];

  const items4 = [
    { 
      id: 10, 
      title: t('item41_title'), 
      textBefore: t('item41_textBefore'),
      textAfter: t('item41_textAfter'),
    },
    { 
      id: 11, 
      title: t('item42_title'), 
      textBefore: t('item42_textBefore'),
      textAfter: t('item42_textAfter'),
    },
    { 
      id: 12, 
      title: t('item43_title'), 
      textBefore: t('item43_textBefore'),
      textAfter: t('item43_textAfter')
    }
  ];

  useLayoutEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          if (divMain.current) {
            setHeight(divMain.current.clientHeight);
          }
        }
      }
    });

    if (divMain.current) {
      setHeight(divMain.current.clientHeight);
      observer.observe(divMain.current, { attributes: true, childList: true, subtree: true });
    }

    return () => {
      if (divMain.current) {
        observer.disconnect();
      }
    };
  }, [window.innerWidth, ]);


  return (
    <div id={'solutions'} className='content1150'>
      <div className='solutions'>
        <div 
          className='solutions_title'
          data-aos="fade-up" 
          data-aos-delay="200" 
          
        >
          <span>
            {t('WE_CREATE')}
          </span> 
          <span className='green'>
            {t('SOLUTIONS')}
          </span>
        </div>

        <div 
          className='solutions_options_columns'
          // data-aos="fade-up" 
          // data-aos-delay="400" 
          // 
        >
          
          <div className='solutions_option_1_columns'>
            <div className='solutions_bullets'>
              <div className='solutions_bullets_main' ref={divMain}>
                <div className='blink'></div>
                <div className='solutions_bullets_main_content'>
                  <div className='solutions_bullets_main_points'>
                    <img src={icon1} alt=''/>
                    <div 
                      className='bullet_title'
                      style={{
                        fontFamily: "Antonio",
                        fontSize: '29px',
                        letterSpacing: '-1.3px'
                      }}
                    >{t('STRATEGY_AND_GROWTH')}</div>
                  </div>
                </div>
              </div>
              <div className='solutions_bullets_shadow' style={{height: height || 0}}></div>
            </div>
            <div className='solutions_option_1_columns_grid'>
              <div className='solutions_option'>
                {items1.map(item => (
                  <SolutionsItem 
                    key={item.id} 
                    id={item.id} 
                    title={item.title} 
                    textBefore={item.textBefore} 
                    textAfter={item.textAfter} 
                    isWideScreen={isWideScreen}
                  />
                ))}
              </div>              
            </div>
          </div>

          <div className='solutions_option_3_columns'>

            <div className='solutions_bullets' style={{width: '100%'}}>
              <div className='solutions_bullets_main' ref={divMain}>
                <div className='blink'></div>
                <div className='solutions_bullets_main_content'>
                  <div className='solutions_bullets_main_points'>
                    <img src={icon2} alt=''/>
                    <div 
                      className='bullet_title'
                      style={{
                        fontFamily: "Antonio",
                        fontSize: '29px',
                        letterSpacing: '-1.3px'
                      }}
                    >{t('COMMUNICATION')}</div>
                  </div>
                </div>
              </div>
              <div className='solutions_bullets_shadow' style={{height: height || 0}}></div>
            </div>

            <div 
              className='solutions_option_3_columns_grid'
            >
              <div className='solutions_option'>
                {items2.map(item => (
                  <SolutionsItem 
                    key={item.id} 
                    id={item.id} 
                    title={item.title} 
                    textBefore={item.textBefore} 
                    textAfter={item.textAfter} 
                    isWideScreen={isWideScreen}
                  />
                ))}
              </div>
              <div className='solutions_option'>
                {items3.map(item => (
                  <SolutionsItem 
                    key={item.id} 
                    id={item.id} 
                    title={item.title} 
                    textBefore={item.textBefore} 
                    textAfter={item.textAfter} 
                    isWideScreen={isWideScreen}
                  />
                ))}
              </div>
              <div className='solutions_option'>
                {items4.map(item => (
                  <SolutionsItem 
                    key={item.id} 
                    id={item.id} 
                    title={item.title} 
                    textBefore={item.textBefore} 
                    textAfter={item.textAfter} 
                    isWideScreen={isWideScreen}
                  />
                ))}
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
