import React from 'react';
import './styles.css'
import Navigation from '../../components/navigation/navigation';
import PageFooter from '../../sections/page_footer/pagefooter';
import PageEffects from '../../sections/page_effects/pageeffects';
import logo from './img/logo.png'
import bg from './img/effects_bg.jpg'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import bullet1 from './img/bullet1.png'
import bullet2 from './img/bullet2.png'
import p1 from './img/pic1.png'
import p2 from './img/pic2.png'
import baner2 from './img/baner2.png'
import baner_mobile from './img/baner_mobile.jpg'
import { Trans } from 'react-i18next';

const Kramp = ({isWideScreen, t, wideScreen}) => {

  const items = [
    {
      icon: icon1,
      title: t('kramp_item_1')
    },
    {
      icon: icon2,
      title: t('kramp_item_2')
    },
  ]


  const animationProps = {
    'data-aos': 'fade-up',
    'data-aos-easing': 'ease-out-cubic'
  };
  

  return (
    <>
      <Navigation isPage={true} isWideScreen={isWideScreen} wideScreen={wideScreen}/>
      <div className='page'>
        <div className='cs_baner_kramp'>
          <div className='cs_baner_container'>
            <img 
              src={logo} 
              alt=''
              data-aos="fade-up"data-aos-delay="100"
            />
            <h2 data-aos="fade-up"data-aos-delay="200">
              <Trans i18nKey={t("kramp_banner_1")}/> 
            </h2>
            <h5 data-aos="fade-up"data-aos-delay="300">
              <Trans i18nKey={t("kramp_banner_2")}/>
            </h5>            
          </div>
        </div>
        <div className='cs_black_space'></div>

        <div className='cs_container_1150_kramp'>

          <div className='challenge'>
            <div className='cs_poranek_row'>
              <div className='cs_bullets'>
                <h2 
                  className='title'
                  data-aos-delay="100" 
                  {...animationProps}
                >
                  {t("challenge")}
                </h2>
                <div 
                  className='cs_bullets_box'
                  data-aos-delay="200" 
                  {...animationProps}
                >
                  <img src={bullet1} alt=''/>
                  <Trans i18nKey={t("kramp_bullet")}/>
                </div>
              </div> 
              <div className='cs_poranki_img'>
                <img src={p1} alt='' {...animationProps} data-aos-delay="300"/>
                <img src={p2} alt='' {...animationProps} data-aos-delay="400"/> 
              </div>               
            </div>
          
          </div>

          <div className='realization'>
            <h2 
              className='title'
              data-aos-delay="100" 
              {...animationProps}
            >
              {t("wedid")}
            </h2>

            <div className='realization-points'>
              <div className='points_column'>
                <div className='point' data-aos-delay="400" {...animationProps}>
                  <img className='point-bullet' src={bullet2} alt=''/>
                  <div>
                    <Trans i18nKey={t("kramp_point_lead")}/>
                    <div className='cs_points_box'>
                      <div className='num-point' {...animationProps} data-aos-delay="100">
                        <div className='num-box'>1.</div>
                        <div className='text-box'><Trans i18nKey={t("kramp_point_1")} components={{ b: <strong /> }}/></div>
                      </div>
                      <div className='num-point' {...animationProps} data-aos-delay="200">
                        <div className='num-box'>2.</div>
                        <div className='text-box'><Trans i18nKey={t("kramp_point_2")} components={{ b: <strong /> }}/></div>
                      </div>
                      <div className='num-point' {...animationProps} data-aos-delay="300">
                        <div className='num-box'>3.</div>
                        <div className='text-box'><Trans i18nKey={t("kramp_point_3")} components={{ b: <strong /> }}/></div>
                      </div>
                      <div className='num-point' {...animationProps} data-aos-delay="400">
                        <div className='num-box'>4.</div>
                        <div className='text-box'><Trans i18nKey={t("kramp_point_4")} components={{ b: <strong /> }}/></div>
                      </div>
                      <div className='num-point' {...animationProps} data-aos-delay="500">
                        <div className='num-box'>5.</div>
                        <div className='text-box'><Trans i18nKey={t("kramp_point_5")} components={{ b: <strong /> }}/></div>
                      </div>
                    </div>      
                  </div>
                </div>                
              </div>
            </div>

            <div className='realization-baner'>
              <img src={isWideScreen ? baner2 : baner_mobile} alt=''/>
            </div>


          </div>
        </div>

        <PageEffects bg={bg} items={items} max={350} t={t}/>
        <PageFooter t={t}/>
      </div>      
    </>

  );
};

export default Kramp;