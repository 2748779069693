import React from 'react'
import './style.css'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import { Trans } from 'react-i18next';

function Needs({isWideScreen, t}) {
  return (
    <div className='content1150'>
      <div className='needs'
      >
        <div 
          className='title'
          data-aos="fade-up" 
          data-aos-delay="100" 
        >
          <span>
            {t('MEET')}
          </span> 
          <span className='green'>
            {t('NEEDS')}
          </span>
        </div>
        <div
          className='bullets'
          data-aos="fade-up" 
          data-aos-delay="200" 
        >
          <div 
            className='bullets_box'
          >
            <div className={!isWideScreen && 'mobile_row'}>
              <img src={icon1} alt=''/>
              {!isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Simplicity and comfort" />
                </div>
              }
            </div>
            
            <div className='bullets_box_content'>
              {isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Simplicity and comfort" />
                </div>              
              }
              <div className='bullets_box_content_p'>
                <Trans i18nKey="Contact one person under one agency for all your marking needs" components={{ b: <strong /> }}/>
                {/* Jedna osoba kontaktowa i jedna agencja dla 
                <b style={{color: '#000'}}>wszystkich Twoich aktywności marketingowych.</b> */}
              </div>
            </div>
          </div>
          <div 
            className='bullets_box'
          >
            <div className={!isWideScreen && 'mobile_row'}>
              <img src={icon2} alt=''/>
              {!isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Efficiency focused on business" />
                </div>
              }
            </div>
            
            <div className='bullets_box_content'>
              {isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Efficiency focused on business" />
                </div>
              }  
              <div className='bullets_box_content_p'>
                <Trans i18nKey="We dont waste your time. Our communication and business offers are designed to increase your sales" components={{ b: <strong /> }}/>
              </div>
            </div>
          </div>
          <div 
            className='bullets_box'
          >
            <div className={!isWideScreen && 'mobile_row'}>
              <img src={icon3} alt=''/>
              {!isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Solutions tailored to your budget" />
                </div>
              }
            </div>
            
            <div className='bullets_box_content'>
              {isWideScreen &&
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Solutions tailored to your budget" />
                </div>
              }   
              <div className='bullets_box_content_p'>
                <Trans i18nKey="Our established team of experts allows us to offer tailored solutions, providing exactly what you need" components={{ b: <strong /> }}/>
              </div>
            </div>
          </div>
          <div 
            className='bullets_box'
          >
            <div className={!isWideScreen && 'mobile_row'}>
              <img src={icon4} alt=''/>
              {!isWideScreen && 
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Refined and impressive projects" />
                </div>
              }
            </div>
            
            <div className='bullets_box_content'>
              {isWideScreen &&
                <div className='bullets_box_content_b'>
                  <Trans i18nKey="Refined and impressive projects" />
                </div>            
              }
              <div className='bullets_box_content_p'>
                <Trans i18nKey="We precisely bring in to life ideas that result in exceptional multichannel brand experiences" components={{ b: <strong /> }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Needs;
