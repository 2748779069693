import React from 'react'
import './style.css'

import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Social() {

  return (
    <ul className='social'>
      <li>
        <a target='_blank' href='https://www.facebook.com/KHSmarketing'>
          <FaFacebookSquare />
        </a>
      </li>
      <li>
        <a target='_blank' href='https://www.instagram.com/agencja_khs/'>
          <FaInstagram />
        </a>
      </li>
      <li>
        <a target='_blank' href='https://www.linkedin.com/company/khs-agencja/'>
          <FaLinkedin />
        </a>
      </li>
    </ul>
  )
}

export default Social