import React, {useEffect, useState} from 'react'
import './style.css'
import { useSpring, animated, useScroll } from '@react-spring/web'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import emailjs from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';
import Checkbox from '../../components/chceckbox/Checkbox'
import logo from '../../components/navigation/img/logo.svg'
import { IoClose } from "react-icons/io5";

const minTranslateY = 200; // minimalne przesunięcie w pionie
const maxTranslateY = -200; // maksymalne przesunięcie w pionie

function Contact({t}) {
  const [{ scrollY }, set] = useSpring(() => ({ scrollY: 0 }))
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [isSubmitChecked, setIsSubmitChecked] = useState(false)


  const handleScroll = () => {
    set({ scrollY: window.scrollY })
  }


  const showErrorToast_pola = () => {
    toast.dismiss();
    toast.error(t("contact_message_1"));
  };



  const showErrorToast = () => {
    toast.dismiss();
    toast.error(t("contact_message_2"));
  };

  const showLoadingToast = () => {
    toast.loading(t("contact_message_3"));
  };
  

  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkForm()) {
      showLoadingToast()
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      emailjs.send('service_brjmafb', 'template_5lteetq', templateParams, 'l1gI3PTlYWXuz2Svw')
        .then((response) => {
          toast.dismiss();
          setModal(true)
        }, (err) => {
          showErrorToast()
        });
       
    } else {
      console.log("uzupełnij pola")
      showErrorToast_pola()
    }
  };
  

  const resetStates = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const [focused, setFocused] = useState({
    name: false,
    email: false,
    subject: false,
    message: false
  });

  const handleFocus = (name) => {
    setFocused({
      ...focused,
      [name]: true
    });
  };

  const handleBlur = (name) => {
    setFocused({
      ...focused,
      [name]: false
    });
  };

  const checkForm = () => {
    if(!checked){
      setIsSubmitChecked(true)
    }else{
      setIsSubmitChecked(false)
    }
    return name && email && subject && message && checked;
  };

  const { scrollYProgress } = useScroll();


  const translateY = scrollYProgress.to({
    range: [0, 1],
    output: [minTranslateY, maxTranslateY],
    extrapolate: 'clamp'
  });

  // biuro@khs.waw.pl



  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if(modal){
      setTimeout(() => {
        setModal(false)
      }, 2500)
    }
  }, [modal])


  useEffect(() => {
    if(checked){
      setIsSubmitChecked(false)
    }
  }, [checked])



  return (
    <div className='contact'>
      <div className='move_graph'>
        <animated.img 
          src={icon2} 
          alt=''
          // style={{ transform: scrollY.interpolate(y => `translateY(${(y/20)}px)`) }}
          style={{ 
            transform: translateY.to(y => `translateY(${y}px)`)
          }}
        />
      </div>

      <div className='move_graph2'>
        <img 
          src={icon1} 
          alt=''
        />
      </div>
      
      <div className='contact_container'>
        <div id='contact' className='contact_handle'></div>
        <div className='contact_grid'>
          <div className='contact_title' data-aos="fade-up" data-aos-delay="100">
            <span>
              {t("contact_title_1")}
            </span> 
            <span className='green'>
              {t("contact_title_2")}
            </span>
          </div>
          <form onSubmit={handleSubmit} className='contact_form'>
            <input 
              value={name}
              onChange={e => setName(e.target.value)}
              type='text' 
              name='name' 
              onFocus={() => handleFocus('name')}
              onBlur={() => handleBlur('name')}
              placeholder={focused.name ? '' : t("contact_contact_form_1")}
            />
            <input 
              value={email}
              onChange={e => setEmail(e.target.value)}
              type='text' 
              name='email' 
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              placeholder={focused.email ? '' : t("contact_contact_form_2")}   
              autocomplete="off"           
            />
            <input 
              value={subject}
              onChange={e => setSubject(e.target.value)}
              type='text'
              name='subject' 
              onFocus={() => handleFocus('subject')}
              onBlur={() => handleBlur('subject')}
              placeholder={focused.subject ? '' : t("contact_contact_form_3")}              
            />
            <textarea 
              value={message}
              onChange={e => setMessage(e.target.value)}
              onFocus={() => handleFocus('message')}
              onBlur={() => handleBlur('message')}
              placeholder={focused.message ? '' : t("contact_contact_form_4")}              
            />
            <div className='endTextarea'></div>

            <div className='contact-checkbox-info' style={!isSubmitChecked ? {height: '1px', opacity: 0} : {height: 'auto', opacity: 1}}>
              <span className='green'>{t("contact_contact_form_5")}</span> {t("contact_contact_form_6")}
            </div>
            <div className='checkboxes'>
              <label>
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </label>
              <p>
                {t("contact_contact_form_7")} <a href={`${process.env.PUBLIC_URL}/img/polityka-prywatnosci-KHS.pdf`} target="_blank" rel="noopener noreferrer" style={{color: '#fff'}}>{t("contact_contact_form_8")}</a>
              </p>
            </div>

            <div>
              <Toaster
                toastOptions={{
                  success: {
                    style: {
                      background: '#e3fff9',
                    },
                  },
                  error: {
                    style: {
                      background: '#ffe3ec',
                    },
                  },
                  loading: {
                    style: {
                      background: '#fff',
                    },
                  },
                }}
              />
            </div>
            <div className='button_row'>
              {/* {isChecked &&
                <ReCAPTCHA
                  sitekey="6LfnLtwpAAAAACojvup3eHf7pHYaftjIbT_JD96f"
                  onChange={handleRecaptcha}
                />              
              } */}

              <div>
                <button 
                  className='btn' 
                  type="submit"
                  // disabled={!checked}
                >
                  {t("contact_contact_btn")}
                
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div onClick={e => setModal(false)} className={`modal-container ${modal ? 'on' : 'off'}`}>
        <div onClick={e => e.stopPropagation()} className={`modal ${modal ? 'on' : 'off'}`}>
          <img src={logo} alt=''/>
          <button onClick={e => setModal(false)}><IoClose /></button>
          <h2 className='green'>{t("contact_message_end_1")}</h2>
          <h3>{t("contact_message_end_2")}</h3>
        </div>
      </div>


    </div>
  )
}

export default Contact